import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { MarketingThemeProvider, VSTypography, Onboarding } from '@lp/ui';

const useStyles = makeStyles(
  {
    container: {
      minHeight: 436,
    },
    title: {
      marginTop: 60,
      marginBottom: 96,
    },
  },
  { classNamePrefix: 'LoadingPage' },
);

export default function LoadingPage() {
  const classes = useStyles();

  return (
    <MarketingThemeProvider>
      <Onboarding>
        <Grid container direction="column" alignItems="center" className={classes.container}>
          <Grid item md={7} xs={10} className={classes.title}>
            <VSTypography variant="h5" component="h1" align="center">
              Hang on a moment while we load your experience…
            </VSTypography>
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Onboarding>
    </MarketingThemeProvider>
  );
}
