import * as Sentry from '@sentry/browser';

const captureMessage = (severity, message, keyValues = []) => {
  Sentry.configureScope(scope => {
    scope.setLevel(severity);
    keyValues.forEach(obj => {
      Object.keys(obj).forEach(key => {
        scope.setExtra(key, obj[key]);
      });
    });
  });
  Sentry.captureMessage(message);
};

export const trackService = {
  track(key, value) {
    Sentry.configureScope(scope => {
      // Arbitrary key/values attached to an event.  Not indexed.
      scope.setExtra(key, value);
    });
  },

  tag(key, value) {
    Sentry.configureScope(scope => {
      // Indexed key/value attached to an event.  Sortable and queryable.
      scope.setTag(key, value);
    });
  },

  captureMessage,

  error(message, keyValues) {
    captureMessage(Sentry.Severity.Error, message, keyValues);
  },

  warn(message, keyValues) {
    captureMessage(Sentry.Severity.Warning, message, keyValues);
  },
};

export const sentryLogAppender = {
  error(logger, message, theError) {
    trackService.track('loggerId', logger.id);
    if (theError instanceof Error) {
      trackService.track('message', message);
      Sentry.captureException(theError);
    } else {
      if (theError) {
        trackService.track('error', theError);
      }
      Sentry.captureException(message);
    }
  },
};
