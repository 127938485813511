export function getLpHost({ appspotHost, allowedDomains, defaultHost }) {
  if (window.location.hostname.endsWith('.appspot.com')) {
    return appspotHost;
  }

  const parts = window.location.host.split('.');
  while (parts.length > 2) {
    parts.shift();
  }
  const domain = parts.join('.');

  return allowedDomains.includes(domain) ? `https://my.${domain}` : defaultHost;
}

export default function commonConfig(apiHost, lpHost) {
  return {
    ACCOUNT_API_HOST: `${apiHost}/account/v1`,
    API_HOST: apiHost,
    ASSET_URI: '/asset/v1/assets',
    BAR_LISTING_URL: `${lpHost}/#/my-conversion-tools/bars/`,
    COLUMN_LIKE_LEVELS: ['column', 'composite', 'widget-column', 'widget'],
    DOMAIN_LISTING_URL: `${lpHost}/my-account/custom-domains/`,
    FEATURES: {},
    FEATURE_FLAG_URL: `${lpHost}/features/list`,
    FIREBASE_TOKEN_API_URL: `${apiHost}/account/v1/api-credentials`,
    LEADBOX_LISTING_URL: `${lpHost}/my-leadboxes/`,
    LEADPAGES_USER_FEEDBACK_URL: 'https://ideas.leadpages.net',
    LEAD_MAGNET_DASHBOARD_URL: `${lpHost}/my-account/files/`,
    LEAD_MAGNET_URL: `${lpHost}/account/v1/users/current/lead-magnets`,
    LEAD_NOTIFICATIONS_DASHBOARD_URL: `${lpHost}/#/my-account/lead-notifications/`,
    LEAD_RANK_ACTIVE_CATEGORIES: [
      'author',
      'consultation',
      'contest',
      'event',
      'download',
      'incentive',
      'lead-capture',
      'sign-up',
      'offer',
      'wait-list',
      'webinar',
    ],
    LEAD_RANK_INACTIVE_CATEGORIES: [
      'thank-you',
      'minisite',
      'about',
      'checkout',
      'downsell',
      'purchase-confirmation',
      'upsell',
    ],
    LEVEL_NAMES: [
      'page',
      'section',
      'layout',
      'column',
      'row',
      'composite',
      'widget-column',
      'widget-row',
      'widget',
    ],
    LEVEL_NAMES_SITE: [
      'site',
      'route',
      'section',
      'layout',
      'column',
      'row',
      'composite',
      'widget-column',
      'widget-row',
      'widget',
    ],
    LP_HOST: lpHost,
    PAGE_LISTING_URL: `${lpHost}/my-pages/`,
    POPUP_LISTING_URL: `${lpHost}/#/my-conversion-tools/pop-ups/`,
    SIGNIN_URL: `${lpHost}/login/`,
    SIGNOUT_URL: `${lpHost}/logout/`,
    SITE_LISTING_URL: `${lpHost}/#/sites/`,
    STANDALONE_LEADBOX_PUBLISH_MODAL: {
      API_HOST: apiHost,
      AUTH_STORAGE_URI: `${lpHost}/sso`,
    },
    STRIPE_REDIRECT_URI: `${lpHost}/my-account/marketplace/stripe/`,
    SUPPORT_KNOWLEDGEBASE_URL: 'https://support.leadpages.com/hc/en-us',
    SYSTEM_FONTS: ['Arial', 'Georgia', 'Times New Roman'],
    TEMPLATE_URL: `${lpHost}/template/v1/user/templates`,
    USER_INFO_URL: `${lpHost}/account/v1/users/current`,
  };
}
