import dockerConfig from './docker.config';
import devConfig from './development.config';
import prodConfig from './production.config';
import stagingConfig from './staging.config';

export default function getBuilderConfig(env) {
  let config;

  switch (env.toLowerCase()) {
    case 'development':
      config = devConfig();
      break;
    case 'staging':
      config = stagingConfig();
      break;
    case 'docker':
    case 'local':
      config = dockerConfig();
      break;
    case 'production':
    default:
      config = prodConfig();
      break;
  }

  return config;
}
